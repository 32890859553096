<template>
  <!-- 组织 -->
  <div class="box-card" style="margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="组织：">
        <el-input v-model="queryForm.organize_name" maxlength="15" @keyup.enter.native="onSubmit" placeholder="请输入组织名称"
          @input="(e) => (queryForm.organize_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="组织管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('632ab003b81f00009300251d')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="isEdit = false; Id = ''; isAddVisible = true;"
            v-if="!isAdmin && isShowBtn('632ab003b81f00009300251c')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
            @size-change="sizeChange" @current-change="currentChange">
             <template slot-scope="{row}" slot="persondata">
              <el-link type="primary" style="margin-right: 10px" v-if="row.persondata.length > 0"
                @click="openPersonDialog(row.persondata)">{{ row.persondata.length }}</el-link>
              <span v-else>{{ row.persondata.length }}</span>
            </template>
             <template slot-scope="{row}" slot="create_time">
              {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template slot-scope="{row}" slot="menu">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editItem(row)"
                v-if="isShowBtn('632ab003b81f00009300251e')"></lbButton>
              <lbButton type="setting" icon="jiagoupeizhi" hint="架构配置" @click="architecture(row)"
                v-if="isShowBtn('632ab003b81f00009300251f')"></lbButton>
              <lbButton type="info" icon="fangke" hint="访客" @click="openQrDialog(row)"
                v-if="isShowBtn('635ab003b81f00009300251f')"></lbButton>
              <lbButton type="succes" icon="shouquanma" hint="授权码" @click="openCodeDialog(row)"
                v-if="isShowBtn('635a8883b81f00009300251f')"></lbButton>
            </template>
          </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
          border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="organize_name" label="组织"></el-table-column>
          <el-table-column prop="addres" label="所在位置" min-width="180"></el-table-column>
          <el-table-column label="组织成员" width="150">
            <template slot-scope="scope">
              <el-link type="primary" style="margin-right: 10px" v-if="scope.row.persondata.length > 0"
                @click="openPersonDialog(scope.row.persondata)">{{ scope.row.persondata.length }}</el-link>
              <span v-else>{{ scope.row.persondata.length }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="180">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editItem(scope.row)"
                v-if="isShowBtn('632ab003b81f00009300251e')"></lbButton>
              <lbButton type="setting" icon="jiagoupeizhi" hint="架构配置" @click="architecture(scope.row)"
                v-if="isShowBtn('632ab003b81f00009300251f')"></lbButton>
              <lbButton type="info" icon="fangke" hint="注册登记" @click="openQrDialog(scope.row)"
                v-if="isShowBtn('635ab003b81f00009300251f')"></lbButton>
              <lbButton type="succes" icon="shouquanma" hint="授权码" @click="openCodeDialog(scope.row)"
                v-if="isShowBtn('635a8883b81f00009300251f')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination popper-class="my-select" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 新增/编辑 -->
    <AddOrganizationManage @close="isAddVisible = false" @editoradd="editadd" :id="Id" :isEdit="isEdit"
      v-if="isAddVisible"></AddOrganizationManage>
    <!-- 组织架构 -->
    <OrganizationalStructure @closewindow="isVisible = false" v-if="isVisible" :id="Id" />
    <lebo-dialog title="组织成员" :isShow="isPersonDialog" width="30%" @close="isPersonDialog = false" footerSlot closeOnClickModal>
      <el-table :data="personTable" border stripe class="table_box" height="460">
        <el-table-column type="index" label="序号" width="100" header-align="center" align="center"></el-table-column>
        <el-table-column property="person_name" label="姓名"></el-table-column>
        <el-table-column property="phone" label="手机号"></el-table-column>
      </el-table>
    </lebo-dialog>
    <!-- 二维码 注册登记-->
    <lebo-dialog append-to-body width="40%" title="注册登记" :isShow="isShowCode" @close="configDialogClose" footerSlot >
      <div class="content_box">
        <el-form :model="configForm" :rules="configFormRules" ref="configFormRefRegist" label-width="150px"
          class="demo-ruleForm">
          <el-form-item label="人员审核：" prop="person_check_status">
            <el-select popper-class="my-select" v-model="configForm.person_check_status" placeholder="请选择审核状态"
              style="width:100%">
              <el-option v-for="item in getOptions('VisitorCheckStatus')" :key="item.value" :label="item.name"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="默认通行权限组：" prop="person_nocheck_permission_group_id" v-if="configForm.person_check_status == 2" style="width:100%">
            <el-select popper-class="my-select" v-model="configForm.person_nocheck_permission_group_id" placeholder="请选择默认通行权限组"
              filterable style="width:100%">
              <el-option v-for="item in permissionGroupSelectList" :key="item._id" :label="item.permission_group_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="qr_content">
          <vue-qr ref="registQrRef" id="qrcode" :text="registQrCodeUrl" :margin="10"></vue-qr>
          <el-button size="small" @click="downQr(2)">下载注册二维码</el-button>
        </div>
      </div>
      <div class="content_box">
        <el-form :model="configForm" :rules="configFormRules" ref="configFormRef" label-width="150px"
          class="demo-ruleForm">
          <el-form-item label="访客审核：" prop="check_status">
            <el-select popper-class="my-select" v-model="configForm.check_status" placeholder="请选择审核状态"
              style="width:100%">
              <el-option v-for="item in getOptions('VisitorCheckStatus')" :key="item.value" :label="item.name"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="默认通行权限组：" prop="nocheck_permission_group_id" v-if="configForm.check_status == 2"
            style="width:100%">
            <el-select popper-class="my-select" v-model="configForm.nocheck_permission_group_id" placeholder="请选择默认通行权限组"
              filterable style="width:100%">
              <el-option v-for="item in permissionGroupSelectList" :key="item._id" :label="item.permission_group_name"
                :value="item._id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="qr_content">
          <vue-qr ref="qrRef" id="qrcode" :text="qrCodeUrl" :margin="10"></vue-qr>
          <el-button size="small" @click="downQr(1)">下载访客二维码</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <lbButton type="goBack" icon="back" @click="isShowCode = false">返 回</lbButton> -->
        <lbButton type="default" fill icon="confirm" @click="submitConfigForm">保 存</lbButton>
      </span>
    </lebo-dialog>
    <!-- 授权码 -->
    <lebo-dialog append-to-body width="20%" title="授权码" :isShow="isShowAuthCode" @close="isShowAuthCode = false" footerSlot closeOnClickModal>
      <span class="autoCode_box">{{ authCode }}</span>
    </lebo-dialog>
  </div>
</template>
<script>
import AddOrganizationManage from './components/addOrganizationManage/index.vue'
import OrganizationalStructure from './components/organizationalStructure/index.vue'
import { updateOrganizationVisitor, getOrgDetail, getPermissionGroupSelectList, getOrganizationPageList, deleteOrganization, getQRCodeUrl } from '@/api/administrative'
import VueQr from 'vue-qr'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    VueQr,
    AddOrganizationManage,
    OrganizationalStructure
  },
  data () {
    return {
      queryForm: {
        organize_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      total: 0,
      tableData: [],
      multipleSelection: [],
      isAddVisible: false, // 是否展示新增对话框
      addressInnerVisible: false, // 是否展示地图对话框
      isVisible: false, // 是否展示组织架构
      isEdit: false,
      Id: '', // 当前展示的组织（编辑或组织架构）
      configForm: {
        _id: '', // 组织id
        nocheck_permission_group_id: '', // 无需审核权限组(值不能为空)
        check_status: 1, // 是否需要审核 1：需审核 2：无需审核
        person_check_status: 1, // 人员注册是否需要审核 1：需要 2：不需要
        person_nocheck_permission_group_id: '' // 组织人员无需审核权限组

      },
      configFormRules: {
        nocheck_permission_group_id: [{ required: true, message: '请选择默认通行权限组', trigger: 'blur' }],
        check_status: [{ required: true, message: '请确认访客是否需要审核', trigger: 'blur' }],
        person_nocheck_permission_group_id: [{ required: true, message: '请选择默认通行权限组', trigger: 'blur' }],
        person_check_status: [{ required: true, message: '请确认注册是否需要审核', trigger: 'blur' }]
      },

      isPersonDialog: false, // 是否展示组织成员对话框
      personTable: [], // 组织成员列表
      permissionGroupSelectList: [], // 权限组下拉列表
      isShowCode: false, // 是否展示二维码
      qrCodeUrl: '', // 访客二维码地址
      registQrCodeUrl: '', // 注册二维码
      isShowAuthCode: false, // 是否展示授权码
      authCode: '', // 授权码
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '组织',
            prop: 'organize_name'
          }, {
            label: '所在位置',
            prop: 'addres'
          }, {
            label: '组织成员',
            prop: 'persondata',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    this.onSubmit()
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin', 'getOptions', 'getWayName'])
  },
  watch: {},
  methods: {
    // 下载二维码
    downQr (type) {
      var name = new Date().getTime()
      var a = document.createElement('a')
      // console.log(this.$refs.qrRef.$el.src)
      a.style.display = 'none'
      a.download = name
      if (type === 1) {
        a.href = this.$refs.qrRef.$el.src
      } else if (type === 2) {
        a.href = this.$refs.registQrRef.$el.src
      }
      document.body.appendChild(a)
      a.click()
    },
    editItem (row) {
      this.isEdit = true
      this.Id = row._id
      this.isAddVisible = true
    },
    architecture (row) {
      this.Id = row._id
      this.isVisible = true
    },
    editadd (status) {
      this.isAddVisible = false
      if (status === 'edit') {
        this.fngetOrgPageList()
      } else {
        this.onSubmit()
      }
    },
    // 获取组织列表
    async fngetOrgPageList () {
      console.log('获取组织列表')
      const res = await getOrganizationPageList(this.queryForm)
      this.total = res && res.Code === 200 ? res.Data.Total : 0
      this.tableData = res && res.Code === 200 ? res.Data.Datalist : []
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.Total : 0
    },
    // 查询
    onSubmit () {
      // console.log(this.queryForm);
      this.queryForm.PageIndex = 1
      this.fngetOrgPageList()
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetOrgPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetOrgPageList()
    // },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetOrgPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetOrgPageList()
    // },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      //  // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('是否确认删除此组织，删除后此组织下的数据也会删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var idArr = []
          this.multipleSelection.forEach((item) => {
            idArr.push(item._id)
          })
          this.deleteOrg({ _ids: idArr })
        }).catch(() => {
          this.$msg.info('已取消删除！')
        })
      } else {
        this.$msg.warning('请选择要删除的组织')
      }
    },
    // 删除组织请求
    async deleteOrg (obj) {
      console.log('删除请求')
      const res = await deleteOrganization(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fngetOrgPageList()
    },
    // 查看组织成员
    openPersonDialog (persondata) {
      this.isPersonDialog = true
      this.personTable = persondata
    },
    // 打开访客对话框
    async openQrDialog (row) {
      console.log('注册登记', row)
      const res = await getQRCodeUrl({
        organize_id: row._id,
        organize_name: row.organize_name,
        type: 1
      })
      const response = await getQRCodeUrl({
        organize_id: row._id,
        organize_name: row.organize_name,
        type: 2
      })
      // console.log(res)
      if (res && res.Code === 200) {
        const date = new Date()
        this.qrCodeUrl = res.Message + `&date=${date}`
      }
      if (response && response.Code === 200) {
        const date = new Date()
        this.registQrCodeUrl = response.Message + `&date=${date}`
      }
      this.isShowCode = true
      this.configForm._id = row._id
      this.fnGetPermissionGroupSelectList(row._id)
    },
    // 打开授权码
    openCodeDialog (row) {
      this.isShowAuthCode = true
      this.authCode = row.auth_code
    },
    // 获取组织详情
    async requestDetail (id) {
      const res = await getOrgDetail({ organi_id: id })
      if (res && res.Code === 200) {
        this.configForm.check_status = res.Data.check_status ? res.Data.check_status : 2
        const findePermissionId = this.permissionGroupSelectList.find(item => {
          return item._id === res.Data.nocheck_permission_group_id
        })
        // 查找通行权限组列表中有无当前选项
        if (!findePermissionId) {
          this.configForm.nocheck_permission_group_id = ''
        } else {
          this.configForm.nocheck_permission_group_id = res.Data.nocheck_permission_group_id !== '000000000000000000000000' ? res.Data.nocheck_permission_group_id : ''
        }
        const findePersonPermissionId = this.permissionGroupSelectList.find(item => {
          return item._id === res.Data.person_nocheck_permission_group_id
        })
        // 查找通行权限组列表中有无当前选项
        if (!findePersonPermissionId) {
          this.configForm.person_nocheck_permission_group_id = ''
        } else {
          this.configForm.person_nocheck_permission_group_id = res.Data.person_nocheck_permission_group_id !== '000000000000000000000000' ? res.Data.person_nocheck_permission_group_id : ''
        }
        this.configForm.person_check_status = res.Data.person_check_status ? res.Data.person_check_status : 2
      }
      // const {
      //   id,
      //   name,
      //   addres,
      //   longitude,
      //   latitude,
      //   province,
      //   province_code,
      //   city,
      //   city_code,
      //   district,
      //   district_code,
      // } = res.Data;
      // this.addForm = {
      //   organize_name: name,
      //   longitude: longitude,
      //   latitude: latitude,
      //   addres: addres,
      //   province: province,
      //   province_code: province_code,
      //   city: city,
      //   city_code: city_code,
      //   district: district,
      //   district_code: district_code,
      // };
    },
    // 获取通行组下拉列表
    async fnGetPermissionGroupSelectList (id) {
      const res = await getPermissionGroupSelectList({ organ_id: id })
      // console.log(res)
      this.permissionGroupSelectList = res && res.Code === 200 ? res.Data : []
      this.requestDetail(id)
    },
    // 保存访客配置
    submitConfigForm () {
      this.$refs.configFormRefRegist.validate(async (valid) => {
        if (valid) {
          this.$refs.configFormRef.validate(async (valid) => {
            if (valid) {
              var obj = {
                _id: this.configForm._id,
                check_status: this.configForm.check_status,
                person_check_status: this.configForm.person_check_status
              }
              if (this.configForm.check_status === 2) obj.nocheck_permission_group_id = this.configForm.nocheck_permission_group_id
              if (this.configForm.person_check_status === 2) obj.person_nocheck_permission_group_id = this.configForm.person_nocheck_permission_group_id
              const res = await updateOrganizationVisitor(obj)
              if (res && res.Code === 200) {
                this.isShowCode = false
              }
            } else {
              return false
            }
          })
        } else {
          return false
        }
      })
    },
    configDialogClose () {
      this.isShowCode = false
      this.$refs.configFormRef.resetFields()
      // console.log(this.$refs.configFormRef.resetField());
    }
  }
}
</script>
<style scoped lang="less">
.table_box {
  /deep/ .el-table__body-wrapper {
    max-height: 431px;
    overflow-y: auto;
  }
}

.content_box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .demo-ruleForm {
    width: 60%;
  }

  .qr_content {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 100%;
    }
  }
}

.autoCode_box {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 10px;
}
</style>
